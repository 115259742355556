import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
// import { FileUpload } from "primereact/fileupload";
// import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
// import { InputTextarea } from "primereact/inputtextarea";
// import { RadioButton } from "primereact/radiobutton";
// import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// import ProductService from "../service/ProductService";
import axios from "axios";
// import { Exception } from "sass";
// https://docs.google.com/spreadsheets/d/1nAc40gTnqT1QHmRLvVJQcwY0iRoB49QTX5Udm4QVQO8/edit?usp=sharing
const Contracts = () => {
    let emptyProduct = {
        id: null,
        propertyId: "",
        flatNo: "",
   
        tenantName: "",
        price: "",
        spaceSqm: "",
        idno: "",
        floor: "",
        tenantType: "",
        bedroom: "",
        bathroom: "",
        electrical: "",
        water: "",
        contact: "",
        dob: "",
        emailaddress: "",
        startdate: "",
        enddate: "",
        mopayment: "",
        maintaindate: "",
        paymentStatus: "",
        contract: "",
        image: null,
        description: "",
      
      
        quantity: 0,
        rating: 0,
        inventoryStatus: "INSTOCK",
    };
    const [fileattach, setfileattach] = useState(null);
    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [importDialog, setImportDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
     
        axios.get("http://localhost:8080/api/tenant").then((data) => setProducts(data.data));
        // productService.getTenants().then(data => setProducts(data));
    }, []);

    // const formatCurrency = (value) => {
    //     return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    // };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };
    const importNew = () => {
        setImportDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const hideImportDialog = () => {
        setImportDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = () => {
        setSubmitted(true);

        if (product.flatNo.trim()) {
            let _products = [...products];
            let _product = { ...product };
            if (product.flatNo) {
                const index = findIndexById(product.flatNo);

                _products[index] = _product;
                axios
                    .post("http://localhost:8080/api/tenant", _product)
                    .then((res) => {
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Tenant Created", life: 3000 });
                    })
                    .catch((Exception) => {
                        toast.current.show({ severity: "an error occured", summary: "Failed", detail: "Tenant Create Failed", life: 3000 });
                    });
                // toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tenant Updated', life: 3000 });
            } else {
                _product.id = createId();
                _product.image = "product-placeholder.svg";
                _products.push(_product);
            }

            setProducts(_products);
            setProductDialog(false);
            setProduct(emptyProduct);
        }
    };

    const editProduct = (product) => {
        setProduct({ ...product });
        alert(`products       ${JSON.stringify(product)}`);
        setProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.flatNo);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Deleted", life: 3000 });
    };

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < products.length; i++) {
            if (products[i].tenantId === id) {
                index = i;
                break;
            }
        }

        return index;
    };

    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    const confirmDeleteSelected = () => {
        setDeleteProductsDialog(true);
    };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Tenant(s) Deleted", life: 3000 });
    };

    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product["propertyId"] = e.value;
    //     setProduct(_product);
    // };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...product };
        _product[`${name}`] = val;

        setProduct(_product);
    };

    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedProducts || !selectedProducts.length} />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Import" icon="pi pi-plus" className="p-button-success mr-2" onClick={importNew} />
                    <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
                    {/* <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} /> */}
                </div>
            </React.Fragment>
        );
    };

    // const codeBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Code</span>
    //             {rowData.tenantName}
    //         </>
    //     );
    // };

    const propertyBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Property</span>
                {rowData.propertyId}
            </>
        );
    };
    const flatNoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Flat No</span>
                {rowData.flatNo}
            </>
        );
    };
    const tenantNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tenant Name</span>
                {rowData.tenantName}
            </>
        );
    };
    // const priceBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Price</span>
    //             {rowData.price}
    //         </>
    //     );
    // }
    const spaceSqmBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Space / Sqm</span>
                {rowData.spaceSqm}
            </>
        );
    };

    const idnoBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Id No</span>
                {rowData.idno}
            </>
        );
    };
    const floorBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Floor</span>
                {rowData.floor}
            </>
        );
    };
    const tenantTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.tenantType}
            </>
        );
    };

    const bedroomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Bedroom</span>
                {rowData.bedroom}
            </>
        );
    };

    const bathroomBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Bathroom</span>
                {rowData.bathroom}
            </>
        );
    };

    const electricalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Electrical</span>
                {rowData.electrical}
            </>
        );
    };
    const waterBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Water</span>
                {rowData.water}
            </>
        );
    };
    const contactBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contact</span>
                {rowData.contact}
            </>
        );
    };
    const dobBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date of Birth</span>
                {rowData.dob}
            </>
        );
    };
    const emailaddressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.emailaddress}
            </>
        );
    };
    const startdateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Start Date</span>
                {rowData.startdate}
            </>
        );
    };
    const enddateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">End Date</span>
                {rowData.enddate}
            </>
        );
    };
    const mopaymentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Payment Method</span>
                {rowData.mopayment}
            </>
        );
    };

    const maintaindateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Maintainance Date</span>
                {rowData.maintaindate}
            </>
        );
    };

    const paymentStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Payment Status</span>
                {rowData.paymentStatus}
            </>
        );
    };
    const contractBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Contract</span>
                {rowData.contract}
            </>
        );
    };

    const tenantBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Tenant Id</span>
                {rowData.tenantId}
            </>
        );
    };

    // const nameBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Name</span>
    //             {rowData.paymentStatus}
    //         </>
    //     );
    // };

    // const imageBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Image</span>
    //             {/* <img src={`assets/demo/images/product/${rowData.image}`} alt={rowData.image} className="shadow-2" width="100" /> */}
    //             <img src={`https://aljaberproperties.com/assets/layout/images/logo-black.png`} alt={rowData.image} className="shadow-2" width="100" />
    //         </>
    //     );
    // };

    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {/* {formatCurrency(rowData.firstNotice)} */}
                {rowData.firstNotice}
            </>
        );
    };

    // const categoryBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Category</span>
    //             {rowData.secondNotice}
    //         </>
    //     );
    // };

    // const ratingBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Reviews</span>

    //             {rowData.lastNotice === 0 ? <p style={{ color: "red" }}>Not Paid</p> : <p style={{ color: "teal" }}>Paid</p>}
    //         </>
    //     );
    // };

    // const statusBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Status</span>
    //             <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>
    //                 <div style={{ color: "red" }}>{rowData.inventoryStatus}</div>
    //             </span>
    //         </>
    //     );
    // };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Contracts</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const uploadImport = () => {
        var formData = new FormData();
        formData.append("file", fileattach);
        axios
            .post("http://localhost:8080/sendFile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                toast.current.show({ severity: "success", summary: "Successful", detail: "Tenant Uploaded", life: 3000 });
                setImportDialog(false);
            })
            .catch((Exception) => {
                toast.current.show({ severity: "failed", summary: "Failed", detail: "Tenant Upload Failed", life: 3000 });
            });
    };

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideImportDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );

    const importDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Import" icon="pi pi-check" className="p-button-text" onClick={uploadImport} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const handleFile = (e) => {
        // const files = e.target.files[0];
        setfileattach(e.target.files[0]);
        // alert(`fileeeeeeeeeeeeeeeeeeeee ${JSON.stringify(e.target.files)}`)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={products}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="tenantId"
                        paginator
                        rows={5}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} tenants"
                        globalFilter={globalFilter}
                        emptyMessage="No Tenants found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                        <Column field="propertyId" header="Property" sortable body={propertyBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="flatNo" header="Flat No" sortable body={flatNoBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="tenantName" header="Tenant Name" sortable body={tenantNameBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="price" header="Price" sortable body={priceBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="spaceSqm" header="Spacce/Sqm" sortable body={spaceSqmBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="idno" header="ID" sortable body={idnoBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                        <Column field="floor" header="Floor" sortable body={floorBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="tenantType" header="Type" sortable body={tenantTypeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="bedroom" header="Bedroom" sortable body={bedroomBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="bathroom" header="Bathroom" sortable body={bathroomBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="electrical" header="Electrical" sortable body={electricalBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="water" header="Water" sortable body={waterBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="contact" header="Contact" sortable body={contactBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="dob" header="Date of Birth" sortable body={dobBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        {/* <Column header="Property" body={imageBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column> */}
                        <Column field="emailaddress" header="Email" body={emailaddressBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "8rem" }}></Column>
                        <Column field="startdate" header="Start Date" sortable body={startdateBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="enddate" header="End Date" body={enddateBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="mopayment" header="Payment Method" body={mopaymentBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="maintaindate" header="Maintain Date" body={maintaindateBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="paymentStatus" header="Payment Status" body={paymentStatusBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="contract" header="Contract" body={contractBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="tenantId" header="Tenant ID" body={tenantBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={importDialog} style={{ width: "500px" }} header="Upload Tenant(s)" modal className="p-fluid" footer={importDialogFooter} onHide={hideImportDialog}>
                        <div className="field">
                            <form id="form" enctype="multipart/form-data" action="http://localhost:8080/sendFile" method="post">
                                <input type="file" name="file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" className="form-control" onChange={handleFile} />
                            </form>
                            {/* <InputText id="flatNo" value={product.flatNo} onChange={(e) => onInputChange(e, 'flatNo')} required autoFocus className={classNames({ 'p-invalid': submitted && !product.flatNo })} /> */}
                            {/* {submitted && !product.flatNo && <small className="p-invalid">flatNo is required.</small>} */}
                        </div>
                    </Dialog>

                    <Dialog visible={productDialog} style={{ width: "500px" }} header="Tenant Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        {product.image && <img src={`assets/demo/images/product/${product.image}`} alt={product.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}
                        <div className="field">
                            <label htmlFor="propertyId">Property ID</label>
                            <InputText id="propertyId" value={product.propertyId} onChange={(e) => onInputChange(e, "propertyId")} required autoFocus className={classNames({ "p-invalid": submitted && !product.propertyId })} />
                            {submitted && !product.propertyId && <small className="p-invalid">Property ID is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="flatNo">Flat No</label>
                            <InputText id="flatNo" value={product.flatNo} onChange={(e) => onInputChange(e, "flatNo")} required autoFocus className={classNames({ "p-invalid": submitted && !product.flatNo })} />
                            {submitted && !product.flatNo && <small className="p-invalid">flatNo is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="tenantName">Tenant Name</label>
                            <InputText id="tenantName" value={product.tenantName} onChange={(e) => onInputChange(e, "tenantName")} required autoFocus className={classNames({ "p-invalid": submitted && !product.tenantName })} />
                            {submitted && !product.tenantName && <small className="p-invalid">Tenant Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="price">Price</label>
                            <InputText id="price" value={product.price} onChange={(e) => onInputChange(e, "price")} required autoFocus className={classNames({ "p-invalid": submitted && !product.price })} />
                            {submitted && !product.price && <small className="p-invalid">Price is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="spaceSqm">Space/Sqm</label>
                            <InputText id="spaceSqm" value={product.spaceSqm} onChange={(e) => onInputChange(e, "spaceSqm")} required autoFocus className={classNames({ "p-invalid": submitted && !product.spaceSqm })} />
                            {submitted && !product.spaceSqm && <small className="p-invalid">Space/Sqm is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="idno">ID NO</label>
                            <InputText id="idno" value={product.idno} onChange={(e) => onInputChange(e, "idno")} required autoFocus className={classNames({ "p-invalid": submitted && !product.idno })} />
                            {submitted && !product.idno && <small className="p-invalid">Id No is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="floor">Floor</label>
                            <InputText id="floor" value={product.floor} onChange={(e) => onInputChange(e, "floor")} required autoFocus className={classNames({ "p-invalid": submitted && !product.floor })} />
                            {submitted && !product.floor && <small className="p-invalid">Floor is required.</small>}
                        </div>{" "}
                        <div className="field">
                            <label htmlFor="tenantType">Type</label>
                            <InputText id="tenantType" value={product.tenantType} onChange={(e) => onInputChange(e, "tenantType")} required autoFocus className={classNames({ "p-invalid": submitted && !product.tenantType })} />
                            {submitted && !product.tenantType && <small className="p-invalid">Tenant Type is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="bedroom">Bedroom</label>
                            <InputText id="bedroom" value={product.bedroom} onChange={(e) => onInputChange(e, "bedroom")} required autoFocus className={classNames({ "p-invalid": submitted && !product.bedroom })} />
                            {submitted && !product.bedroom && <small className="p-invalid">Bedroom is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="bathroom">Bathroom</label>
                            <InputText id="bathroom" value={product.bathroom} onChange={(e) => onInputChange(e, "bathroom")} required autoFocus className={classNames({ "p-invalid": submitted && !product.bathroom })} />
                            {submitted && !product.bathroom && <small className="p-invalid">Bathroom is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="electrical">Electrical</label>
                            <InputText id="electrical" value={product.electrical} onChange={(e) => onInputChange(e, "electrical")} required autoFocus className={classNames({ "p-invalid": submitted && !product.electrical })} />
                            {submitted && !product.electrical && <small className="p-invalid">Electrical is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="water">Water</label>
                            <InputText id="water" value={product.water} onChange={(e) => onInputChange(e, "water")} required autoFocus className={classNames({ "p-invalid": submitted && !product.water })} />
                            {submitted && !product.water && <small className="p-invalid">Water is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contact">Contact</label>
                            <InputText id="contact" value={product.contact} onChange={(e) => onInputChange(e, "contact")} required autoFocus className={classNames({ "p-invalid": submitted && !product.contact })} />
                            {submitted && !product.contact && <small className="p-invalid">Contact is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="dob">Date of Birth</label>
                            <InputText id="dob" value={product.dob} onChange={(e) => onInputChange(e, "dob")} required autoFocus className={classNames({ "p-invalid": submitted && !product.dob })} />
                            {submitted && !product.dob && <small className="p-invalid">Date of Birth is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="emailaddress">Email</label>
                            <InputText id="emailaddress" value={product.emailaddress} onChange={(e) => onInputChange(e, "emailaddress")} required autoFocus className={classNames({ "p-invalid": submitted && !product.emailaddress })} />
                            {submitted && !product.emailaddress && <small className="p-invalid">Email is required.</small>}
                        </div>{" "}
                        <div className="field">
                            <label htmlFor="startdate">Start Date</label>
                            <InputText id="startdate" value={product.startdate} onChange={(e) => onInputChange(e, "startdate")} required autoFocus className={classNames({ "p-invalid": submitted && !product.startdate })} />
                            {submitted && !product.startdate && <small className="p-invalid">Start Date is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="enddate">End Date</label>
                            <InputText id="enddate" value={product.enddate} onChange={(e) => onInputChange(e, "enddate")} required autoFocus className={classNames({ "p-invalid": submitted && !product.enddate })} />
                            {submitted && !product.enddate && <small className="p-invalid">End Date is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="mopayment">Payment Method</label>
                            <InputText id="mopayment" value={product.mopayment} onChange={(e) => onInputChange(e, "mopayment")} required autoFocus className={classNames({ "p-invalid": submitted && !product.mopayment })} />
                            {submitted && !product.mopayment && <small className="p-invalid">Payment Method is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="maintaindate">Maintainance Date</label>
                            <InputText id="maintaindate" value={product.maintaindate} onChange={(e) => onInputChange(e, "maintaindate")} required autoFocus className={classNames({ "p-invalid": submitted && !product.maintaindate })} />
                            {submitted && !product.maintaindate && <small className="p-invalid">Maintainance Date is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="paymentStatus">Payment Status</label>
                            <InputText id="paymentStatus" value={product.paymentStatus} onChange={(e) => onInputChange(e, "paymentStatus")} required autoFocus className={classNames({ "p-invalid": submitted && !product.paymentStatus })} />
                            {submitted && !product.paymentStatus && <small className="p-invalid">Payment Status is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="contract">Contract</label>
                            <InputText id="contract" value={product.contract} onChange={(e) => onInputChange(e, "contract")} required autoFocus className={classNames({ "p-invalid": submitted && !product.contract })} />
                            {submitted && !product.contract && <small className="p-invalid">Contract is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && (
                                <span>
                                    Are you sure you want to delete <b>{product.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && <span>Are you sure you want to delete the selected tenant ?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Contracts, comparisonFn);
