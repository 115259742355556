import React from 'react';

const AppFooter = () => {

    return (
        <div className="layout-footer">
            <div className="footer-logo-container">
                {/* <img id="footer-logo" src="assets/layout/images/logo-black.png" alt="diamond-layout" /> */}
                {/* <span className="app-name">Aljaber</span> */}
            </div>
            <span className="copyright">&#169; Aljaber Properties - 2022</span>
        </div>
    );
}

export default AppFooter;
