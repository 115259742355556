import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
// import axios from "axios";
import { useHistory } from "react-router-dom";
export const Login = () => {
    const history = useHistory();
    const [state, setState] = React.useState({
        username: "",
        password: "",
    });
    // const [loginclicked, setLoginclicked] = React.useState(false);
    const [error, Seterror] = useState("");
    const sendDetailsToServer = () => {
        // setLoginclicked(true);
        if (state.username.length && state.password.length) {
            // const payload = {
            //     username: state.username.toLowerCase(),
            //     password: state.password,
            // };
            localStorage.setItem("user", state.username.toLowerCase());
            localStorage.setItem("usermail", state.username.toLowerCase());
            localStorage.setItem("userrole", state.username.toLowerCase());
            Seterror(null);
            history.push("/");
            // axios
            //     .post("http://localhost:8080/api/auth/signin", payload)
            //     .then(function (response) {
            //         // var userrole=response.data.role
            //         // alert(JSON.stringify(response.data))

            //     })
            //     .catch(function (error) {
            //         Seterror("invalid credentials");
            //         console.log("invalid credentials");
            //     });
        } else {
            Seterror("Please enter valid username and password");
        }
    };
    const handleChange = (evt) => {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    };
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "d9fca5d4-16b4-4c6c-a9e8-71be2c0cf8a9";

    (function () {
        var d = document;
        var s = d.createElement("script");

        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
    })();

    return (
        <div className="login-body">
            <div className="login-wrapper">
                <div className="login-panel">
                    <img src="assets/layout/images/logo-black.png" className="logo" alt="diamond-layout" />

                    <div className="login-form">
                        <h2>Login</h2>
                        <p>Welcome, please enter your credentials to sign-in.</p>
                        {/* <InputText placeholder="Full Name" /> */}
                        <InputText placeholder="Username" name="username" onChange={handleChange} />
                        <Password placeholder="Password" name="password" type="password" onChange={handleChange} />

                        {/* {
    loginclicked && !error ?
    <div style={{alignItems:'center'}}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<LinearProgress  style={{alignItems:'center',width:'400px'}}/>
                    </div>
    :
    null
} */}

                        <p style={{ color: "red" }}>{error}</p>
                        <Button label="Login" type="button" onClick={sendDetailsToServer}></Button>
                    </div>

                    <p>A problem? Click on the chat icon and let us help you.</p>
                </div>
                <div className="login-image">
                    <div className="login-image-content">
                        {/* <h1>Access to your</h1>
                        <h1>Aljaber Properties</h1>
                        <h1>Account</h1> */}
                    </div>
                    <div className="image-footer">
                        <p>
                            Aljaber Properties <span className="copyright">&#169; </span> 2023
                        </p>
                        <div className="icons">
                            <i className="pi pi-facebook"></i>
                            <i className="pi pi-twitter"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
