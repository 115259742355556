// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

import { getAuth,signInWithEmailAndPassword,signOut,sendPasswordResetEmail } from "firebase/auth";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDYEXk8arLBTyIwlL2h8vT_KGBPiIL9gdE",
  authDomain: "aljaberproperties-6e729.firebaseapp.com",
  projectId: "aljaberproperties-6e729",
  storageBucket: "aljaberproperties-6e729.appspot.com",
  messagingSenderId: "905265722300",
  appId: "1:905265722300:web:ac9b71cc95acc29893f1b9"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage=getStorage(app)
const auth = getAuth(app);
const db = getFirestore(app)
const logInWithEmailAndPassword = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const sendPasswordReset = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset link sent!");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };
  const logout = () => {
    signOut(auth);
  };
  export {auth,db,logInWithEmailAndPassword,logout,sendPasswordReset,firebaseConfig,storage}