import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import axios from "axios";
import { Toast } from "primereact/toast";

const ContractsUpload = () => {
    // const [dropdownItem, setDropdownItem] = useState(null);
    const [propertyItem, setPropertyItem] = useState(null);
    const [MaintainanceCount, setMaintainanceCount] = useState(null);
    const toast = useRef(null);
    useEffect(() => {
        axios
            .get("https://al-jaber.herokuapp.com/api/maintenance")
            .then((res) => {
                setMaintainanceCount(res.data.length);
                localStorage.setItem("maintcount", res.data.length);
            })
            .finally(() => {
                // setLoading(false);
            });
    }, []);

    let emptyRequest = {
        username: localStorage.getItem("user"),
        email: localStorage.getItem("usermail"),
        message: "",
        propertyUnit: "",
        propertyId: "",
        ticketNumber: "--------",
        ticketStatus: "open",
    };
    const [maintainRequest, setMaintainRequest] = useState(emptyRequest);
    const [submitted, setSubmitted] = useState(false);
    const [fileattach, setfileattach] = useState("");

    // const dropdownItems = [
    //     { name: "Cash", code: "Cash" },
    //     { name: "Bank Transfer", code: "Bank Transfer" },
    // ];
    const properties = [
        {
            propertyUnit: null,
            propertyId: "Deen20210920114256",
            propertyName: "A40",
            propertyDescription: "A40",
            __v: 0,
        },
        {
            propertyUnit: null,
            propertyId: "Deen20210921000021",
            propertyName: "Um Salal",
            propertyDescription: "Um Salal",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
            ],
            propertyId: "Deen20210921000040",
            propertyName: "Al-Wakrah",
            propertyDescription: "Al-Wakrah",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000142",
            propertyName: "Al-Ghanem",
            propertyDescription: "Al-Ghanem",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000202",
            propertyName: "Al-Jaber HQ",
            propertyDescription: "Al-Jaber HQ",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000214",
            propertyName: "Lagoon",
            propertyDescription: "Lagoon",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000245",
            propertyName: "Al-Gharafah",
            propertyDescription: "Al-Gharafah",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000304",
            propertyName: "Indian Embassy",
            propertyDescription: "Indian Embassy",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000328",
            propertyName: "Duhail",
            propertyDescription: "Duhail",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921000357",
            propertyName: "Old Airport",
            propertyDescription: "Old Airport",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "Deen20210921105840",
            propertyName: "D31",
            propertyDescription: "Lusail D31",
            __v: 0,
        },
        {
            propertyId: "A2020211020022530",
            propertyUnit: [
                {
                    status: "1",
                },
            ],
            propertyName: "A20",
            propertyDescription: "A20",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "1",
                },
                {
                    status: "2",
                },
            ],
            propertyId: "unittesting20211027083721",
            propertyName: "unittesting",
            propertyDescription: "unit testing",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Mohammad Sultan Al-Jaber20211117105749",
            propertyName: "Mohammad Sultan Al-Jaber",
            propertyDescription: "Owner House",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Huda Al-Jaber20211121055558",
            propertyName: "Huda Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Ali Al-Jaber20211121055611",
            propertyName: "Ali Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Salah Al-Jaber20211121055641",
            propertyName: "Salah Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Adel Al-Jaber20211121055653",
            propertyName: "Adel Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Yousef Al-Jaber20211121060524",
            propertyName: "Yousef Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyId: "Mariam Al-Jaber20211123091831",
            propertyName: "Mariam Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Sultan Al-Jaber20211123091847",
            propertyName: "Sultan Al-Jaber",
            propertyDescription: "Villa",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Al Jaber Twin Tower20211123092001",
            propertyName: "Al Jaber Twin Tower",
            propertyDescription: "Office Tower",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Al Jaber Twin Tower20211123092009",
            propertyName: "Al Jaber Twin Tower",
            propertyDescription: "Mall",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Century Marina Hotel20211123092022",
            propertyName: "Century Marina Hotel",
            propertyDescription: "Hotel",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Industrial Area20211123092041",
            propertyName: "Industrial Area",
            propertyDescription: "38",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Industrial Area20211123092110",
            propertyName: "Industrial Area",
            propertyDescription: "48",
            __v: 0,
        },
        {
            propertyUnit: [],
            propertyId: "Al-Khor Camp20211123092126",
            propertyName: "Al-Khor Camp",
            propertyDescription: "Camp Site",
            __v: 0,
        },
        {
            propertyUnit: [
                {
                    status: "001",
                },
                {
                    status: "002",
                },
            ],
            propertyId: "D3120220214092032",
            propertyName: "D31",
            propertyDescription: "22",
            __v: 0,
        },
    ];
    const saveProduct = () => {
        setSubmitted(true);
        let _maintainRequest = { ...maintainRequest };
        var pload = new FormData();
        pload.append("file", fileattach);
        pload.append("upload_preset", "ayomideio");
        pload.append("cloud_name", "dvjdsipkg");

        for (var i = 0; i < 1; i++) {
            fetch(" https://api.cloudinary.com/v1_1/dvjdsipkg/image/upload", {
                method: "post",
                body: pload,
            })
                .then((resp) => resp.json())
                .then((data) => {
                    setfileattach(data.url);
                    // alert(`dataaaaaaaaa ${JSON.stringify(data)}`)
                    const payload = {
                        operate: "create",
                        sendmail: "yes",
                        count: `00${MaintainanceCount}`,
                        email: _maintainRequest.email,
                        username: _maintainRequest.username,
                        ticketnumber: _maintainRequest.ticketNumber,
                        attachment: data.url,
                        message: _maintainRequest.message,
                        ticketstatus: _maintainRequest.ticketStatus,
                        rop: _maintainRequest.propertyUnit,
                        propertyName: propertyItem,
                    };

                    axios.post("http://localhost:8080/api/maintenance", payload);
                    toast.current.show({ severity: "success", summary: "We have received your request and will respond soon", detail: "Tenant Created", life: 3000 });
                });
            // axios.post("http://localhost:8080/api/tenant",_maintainRequest).then(res=>{
            //             toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Tenant Created', life: 3000 });
            //         }).catch(Exception=>{
            //             toast.current.show({severity:'an error occured', summary:'Failed',detail:'Tenant Create Failed',life:3000})
            //         })
            setMaintainRequest(emptyRequest);
        }
    };
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _maintainRequest = { ...maintainRequest };
        _maintainRequest[`${name}`] = val;

        setMaintainRequest(_maintainRequest);
    };
    const handleFile = (e) => {
        // const files = e.target.files[0];
        setfileattach(e.target.files[0]);
        // alert(`fileeeeeeeeeeeeeeeeeeeee ${JSON.stringify(e.target.files)}`)
    };

    return (
        <div className="grid">
            <Toast ref={toast} />
            <div className="col-12">
                <div className="card">
                    <div className="p-fluid formgrid grid">
                        <div className="field col-12 md:col-6">
                            <label htmlFor="firstname2">Username</label>
                            <Dropdown id="state" value={propertyItem} onChange={(e) => setPropertyItem(e.value)} options={properties} optionLabel="propertyName" placeholder="Select One"></Dropdown>
                            {/* <InputText id="username" type="text"   value={maintainRequest.username} onChange={(e) => onInputChange(e, 'username')} required autoFocus className={classNames({ 'p-invalid': submitted && !maintainRequest.username })} /> */}
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="lastname2">Ticket Number</label>
                            <InputText id="ticketNumber" type="text" value={maintainRequest.ticketNumber} onChange={(e) => onInputChange(e, "ticketNumber")} required autoFocus className={classNames({ "p-invalid": submitted && !maintainRequest.ticketNumber })} />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="address">Message</label>
                            <InputTextarea id="message" rows="4" value={maintainRequest.message} onChange={(e) => onInputChange(e, "message")} required autoFocus className={classNames({ "p-invalid": submitted && !maintainRequest.message })} />
                        </div>
                        <div className="field col-12 md:col-6">
                            <label htmlFor="city">Upload Image</label>
                            <InputText id="city" type="file" accept="image/*" onChange={handleFile} />
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="state">Property ID</label>
                            <Dropdown id="state" value={propertyItem} onChange={(e) => setPropertyItem(e.value)} options={properties} optionLabel="propertyName" placeholder="Select One"></Dropdown>
                        </div>
                        <div className="field col-12 md:col-3">
                            <label htmlFor="zip">Property Unit</label>
                            <InputText id="propertyUnit" type="number" value={maintainRequest.propertyUnit} onChange={(e) => onInputChange(e, "propertyUnit")} required autoFocus className={classNames({ "p-invalid": submitted && !maintainRequest.propertyUnit })} />
                        </div>
                        <div style={{ height: "100px" }}></div>
                        <div className="field col-12 md:col-3">
                            <Button label="Send Request" className="p-button-success mr-2 mb-2" style={{ width: "150px" }} onClick={saveProduct} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ContractsUpload, comparisonFn);
