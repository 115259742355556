import React, { useState, useEffect, useRef } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";

import { Toolbar } from "primereact/toolbar";

// import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ProductService from "../service/ProductService";
import { collection, query, onSnapshot, doc, updateDoc, addDoc } from "firebase/firestore";
import { db, logout } from "../fumctions/firebase";
import { Dropdown } from "primereact/dropdown";

// import request from 'req'

const Property = () => {
    let emptyProduct = {
        data: {
            id: null,

            cheapest_time: [],
            property_name: "",
            property_type: "",
            floors: [],
        },
    };

  
    const property_types = ["Commercial", "Residential"];
    const apartment_types = ["1BHK", "Studio", "2BHK", "3BHK"];

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [users, setUsers] = useState([]);
    // const [notificationFormat, setNotificationFormat] = useState(null);
    const [inputFields, setInputFields] = useState([{ floor: "", units: "", apartment_type: "" }]);

    useEffect(() => {
        // sendNotification();
        const productService = new ProductService();
        productService.getProducts().then((data) => setProducts(data));
        const q = query(collection(db, "properties"));
        onSnapshot(q, (querySnapshot) => {
            // console.log(`the users ${JSON.stringify(querySnapshot)}`);
            setUsers(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // var ade = [];
            querySnapshot.docs.map((doc) => ({
                dt: doc.data(),
            }));
        });
        const f = query(collection(db, "notificationformat"));
        onSnapshot(f, (querySnapshot) => {
            // console.log(`the notification ${JSON.stringify(querySnapshot)}`);
            // setNotificationFormat(
            //     querySnapshot.docs.map((doc) => ({
            //         data: doc.data(),
            //     }))
            // );
        });
    }, []);

    // const formatCurrency = (value) => {
    //     return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    // };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };

    const saveProduct = async () => {
        setSubmitted(true);

        if (product.id) {
            // let _products = [...products];
            // let _product = { ...product };

            const taskDocRef = doc(db, "properties", product.id);
            try {
                await updateDoc(taskDocRef, {
                    property_name: product.data.property_name,
                    propertyId:product.data.property_name,
                    property_type: product.data.property_type,
                    floors: inputFields,
                }).then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Property Updated", life: 3000 });
                    setProductDialog(false);
                    setProduct(emptyProduct);
                });
            } catch (err) {
                alert(err);
            }
            // setUsers(_products);
        } else {
            try {
                await addDoc(collection(db, "properties"), {
                    property_name: product.data.property_name,
                    propertyId:product.data.property_name,
                    property_type: product.data.property_type,
                    floors: inputFields,
                }).then((res) => {
                    toast.current.show({ severity: "success", summary: "Successful", detail: "Property Created", life: 3000 });
                    setProductDialog(false);
                    setProduct(emptyProduct);
                });
            } catch (err) {
                alert(err);
            }
        }
    };

    const editProduct = (product) => {
        console.log(`product details  ${JSON.stringify(product)}`);
        setProduct({ ...product });
        setInputFields(product.data.floors);
        setProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Product Deleted", life: 3000 });
    };

    // const findIndexById = (id) => {
    //     let index = -1;
    //     for (let i = 0; i < products.length; i++) {
    //         if (products[i].id === id) {
    //             index = i;
    //             break;
    //         }
    //     }

    //     return index;
    // };

    // const createId = () => {
    //     let id = "";
    //     let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    //     for (let i = 0; i < 5; i++) {
    //         id += chars.charAt(Math.floor(Math.random() * chars.length));
    //     }
    //     return id;
    // };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    // const confirmDeleteSelected = () => {
    //     setDeleteProductsDialog(true);
    // };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product["category"] = e.value;
    //     setProduct(_product);
    // };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...product };
        _product.data[`${name}`] = val;

        setProduct(_product);
    };

    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // };
    // const timeNow = () => {
    //     var d = new Date(),
    //         h = (d.getHours() < 10 ? "0" : "") + d.getHours(),
    //         m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    //     return h + ":" + m;
    // };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-send" className="p-button-success mr-2" onClick={openNew} />

                    {/* {JSON.stringify(notificationFormat[0].data.title)} */}
                    <Button
                        label="Log Out"
                        icon="pi pi-switch"
                        className="p-button-danger"
                        onClick={logout}
                        //  disabled={!selectedProducts || !selectedProducts.length}
                    />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    // const codeBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Codsdse</span>
    //             {rowData.data["firstname"]}
    //         </>
    //     );
    // };

    // const nameBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Name</span>
    //             {rowData.data["lastname"]}
    //         </>
    //     );
    // };

    // const imageBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Image</span>

    //             <img src={`${rowData.data["image_url"]}`} alt={"user image"} className="shadow-2" width="100" />
    //         </>
    //     );
    // };

    const categoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Property Name</span>
                {rowData.data["property_name"]}
            </>
        );
    };
    const propertyTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Property Type</span>
                {rowData.data["property_type"]}
            </>
        );
    };

    const ratingBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Floor, Units & Apartment Type</span>
                {rowData.data
                    ? rowData.data["floors"].map((rw) => {
                          return rw.floor + "-" + rw.units + "-" + rw.apartment_type;
                      })
                    : null}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Properties</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        data[index][event.target.name] = event.target.value;
        setInputFields(data);
    };
    const addFields = () => {
        let newfield = { floor: "", units: "", apartment_type: "" };

        setInputFields([...inputFields, newfield]);
    };
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={users}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} property(s)"
                        globalFilter={globalFilter}
                        emptyMessage="No property found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

                        <Column field="property_name" header="Property Name" sortable body={categoryBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="property_name" header="Property Type" sortable body={propertyTypeBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="floors" header="Floor, Units & Apartment Type" body={ratingBodyTemplate} sortable headerStyle={{ width: "50%", minWidth: "10rem" }}></Column>

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "900px" }} header="Property Details" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <div style={{ height: "5px" }}></div>
                            <label htmlFor="name">Property Name</label>

                            <InputText id="projectname" value={product.data.property_name} onChange={(e) => onInputChange(e, "property_name")}></InputText>
                            {submitted && !product.data.property_name && <small className="p-invalid">Property Name is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="name">Property Type</label>

                            <Dropdown id="projectname" value={product.data.property_type} onChange={(e) => onInputChange(e, "property_type")} options={property_types} placeholder="Select Property Type"></Dropdown>
                            {submitted && !product.data.property_type && <small className="p-invalid">Property Type is required.</small>}
                        </div>

                        {inputFields.map((input, index) => {
                            return (
                                <div key={index} className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="price">Floor</label>
                                        <InputText name="floor" value={input.floor} onChange={(event) => handleFormChange(index, event)} />
                                        {submitted && !input.floor && <small className="p-invalid">Floor is required.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="price">Unit</label>
                                        <InputText name="units" value={input.units} onChange={(event) => handleFormChange(index, event)} />
                                        {submitted && !input.units && <small className="p-invalid">Unit is required.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="price">Apartment Type</label>
                                        <Dropdown name="apartment_type" value={input.apartment_type} onChange={(event) => handleFormChange(index, event)} options={apartment_types} placeholder="Select Apartment Type" />
                                        {/* <Dropdown name="electricityRate" value={input.electricityRate} onChange={(event) => handleFormChange(index, event)} autoFocus className={classNames({ "p-invalid": submitted && !inputFields.electricityRate})} /> */}
                                        {submitted && !input.apartment_type && <small className="p-invalid">Apartment Type is required.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="courtroomnumber"> Delete </label>
                                        <Button
                                            label="Delete"
                                            icon="pi pi-trash"
                                            className="p-button-danger"
                                            onClick={() => removeFields(index)}
                                            //  disabled={!selectedProducts || !selectedProducts.length}
                                        />
                                        {/* <Button label="Add" icon="pi pi-check" className="p-button-success mr-2" onClick={addFields} /> */}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="field col">
                            <label htmlFor="courtroomnumber"> Action </label>
                            <Button label="Add" icon="pi pi-check" className="p-button-success mr-2" onClick={addFields} />
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && (
                                <span>
                                    Are you sure you want to delete <b>{product.data.property_name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Property, comparisonFn);
