import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
// import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Checkbox } from 'primereact/checkbox';
import { Calendar as PRCalendar } from 'primereact/calendar';
import { InputTextarea } from "primereact/inputtextarea";
// import { RadioButton } from "primereact/radiobutton";
// import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ProductService from "../service/ProductService";
import { collection, query,  onSnapshot, doc, updateDoc, addDoc} from "firebase/firestore";
import { db,  logout, storage } from "../fumctions/firebase";
import { Dropdown } from "primereact/dropdown";
// import { MultiSelect } from "primereact/multiselect";
// import firebase from "firebase/app";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import Axios from "axios";
import { Calendar } from "primereact/calendar";
// import CalendarDemo from "./CalendarDemo";

// import request from 'req'
const ticketStatus = ["Open", "Closed"];
const AljaberMaintenance = () => {
    const createId = () => {
        let id = "";
        let chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < 5; i++) {
            id += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return id;
    };
    let emptyProduct = {
        data: {
            id: null,
            operate: "create",
            sendmail: "yes",
            count: `001`,
            email: "",
            username: "",
            ticketnumber: createId,

            maintenancedate: "",

            message: "",
            ticketstatus: "",
            rop: "",
            propertyName: "",
            file_uri: [],
        },
    };

    const doc_types = ["VIDEO", "AUDIO", "IMAGES", "PDF", "URL"];
    // const cities = ["OSLO", "KR. SAND", "BERGEN", "TR. HEIM", "MOLDE", "Tromso"];

    const [products, setProducts] = useState(null);
    const [productDialog, setProductDialog] = useState(false);
    const [calendarDialog, setCalendarDialog] = useState(false);
    const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
    const [product, setProduct] = useState(emptyProduct);
    const [selectedProducts, setSelectedProducts] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [users, setUsers] = useState([]);
    const [modules, setModules] = useState([]);
    const [downloadUrls, setDownloadUrls] = useState([]);
    // const [notificationFormat, setNotificationFormat] = useState(null);
    const [inputFields, setInputFields] = useState([{ document_type: "", file: "" }]);
    const [eventDialog, setEventDialog] = useState(false);
    const [clickedEvent, setClickedEvent] = useState(null);
    const [changedEvent, setChangedEvent] = useState({ title: '', start: null, end: null, allDay: null });
    const [events, setEvents] = useState( [
    	{
            "id": 1,
    		"title": "All Day Event",
    		"start": "2023-01-30"
    	},
    	{
            "id": 2,
    		"title": "Long Event",
    		"start": "2023-01-09",
    		"end": "2023-01-12"
    	},]);

    useEffect(() => {
        // sendNotification();
        const productService = new ProductService();
        productService.getProducts().then((data) => setProducts(data));
        const q = query(collection(db, "maintenance"));
        onSnapshot(q, (querySnapshot) => {
            // console.log(`the users ${JSON.stringify(querySnapshot)}`);
            setUsers(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // var ade = [];
           var tobefm= querySnapshot.docs.map((doc) => ({
                data: doc.data(),
            }));
            var formatDocument=formatModule(tobefm)
            console.log(`formatted doc ${JSON.stringify(formatDocument)}`);
  
        var eventss_=[]
        for (var i=0;i<formatDocument.length;i++){
      eventss_.push(          {
        "id": formatDocument[i].ticketnumber,
    "title": formatDocument[i].message,
    "start": formatDocument[i].maintenancedate
  })
        }
        setEvents(eventss_)

        });
        const f = query(collection(db, "properties"));
        onSnapshot(f, (querySnapshot) => {
            // var tobeFormatted = querySnapshot.docs.map((doc) => ({
            //     data: doc.data(),
            // }));
            // var formatDoc = formatModule(tobeFormatted);
            setModules(
                querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    data: doc.data(),
                }))
            );
            // console.log(`formatted doc ${JSON.stringify(formatDoc)}`);
        });
    }, []);

    const openCalendar = () => {
        setCalendarDialog(true);
    };

    const openNew = () => {
        setProduct(emptyProduct);
        setSubmitted(false);
        setProductDialog(true);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setProductDialog(false);
        setCalendarDialog(false)
    };

    const hideDeleteProductDialog = () => {
        setDeleteProductDialog(false);
    };

    const hideDeleteProductsDialog = () => {
        setDeleteProductsDialog(false);
    };
    const formatDate = (dateValue) => {
        var date = new Date(dateValue);
        // Get year, month, and day part from the date
        var year = date.toLocaleString("default", { year: "numeric" });
        var month = date.toLocaleString("default", { month: "2-digit" });
        var day = date.toLocaleString("default", { day: "2-digit" });

        // Generate yyyy-mm-dd date string
        var formattedDate = year + "-" + month + "-" + day;
        console.log(formattedDate);
        return formattedDate;
    };
    const saveProduct = async () => {
        setSubmitted(true);

        if (product.id) {
            // let _products = [...products];
            // let _product = { ...product };

            const taskDocRef = doc(db, "cheapestHour", product.id);
            try {
                await updateDoc(taskDocRef, {
                    city: product.data.city,

                    hourlyRate: inputFields,
                }).then((res) => {
                    toast.current.show({
                        severity: "success",
                        summary: "Successful",
                        detail: "City Updated",
                        life: 3000,
                    });
                    setProductDialog(false);
                    setProduct(emptyProduct);
                });
            } catch (err) {
                alert(err);
            }
            // setUsers(_products);
        } else {
            try {
               await Promise.all(
                    inputFields.map((fn) => {
                        const storageRef = ref(storage, `/Maintenance-Request/${fn.document_type}/${fn.file.name}`);
                        const uploadTask = uploadBytesResumable(storageRef, fn.file);
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => {
                                const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                                toast.current.show({
                                    severity: "Upload Progress",
                                    summary: percent,
                                    detail: "Maintenance E-mail Sent",
                                    life: 3000,
                                });
                            },
                            (err) => console.log(err),
                            async () => {
                                // download url
                                await getDownloadURL(uploadTask.snapshot.ref)
                                    .then(async (url) => {
                                        console.log(url);
                                        console.log(`url mapping completed`);

                                        var dUrl = downloadUrls;

                                        dUrl.push({ uri: url, document_type: fn.document_type, component_id: product.data.component_id });

                                        await addDoc(collection(db, "maintenance"), {
                                            operate: "create",
                                            sendmail: "yes",
                                            count: product.data.count,
                                            email: product.data.email,
                                            username: product.data.username,
                                            ticketnumber: product.data.ticketnumber,
                                            maintenancedate: formatDate(product.data.maintenancedate),
                                            attachment: url,
                                            message: product.data.message,
                                            ticketstatus: product.data.ticketstatus,
                                            rop: "1",
                                            propertyName: product.data.propertyName,
                                            file_uri: [{ document_type: inputFields[0].document_type, file: "" }],
                                        }).then((res) => {
                                            toast.current.show({ severity: "success", summary: "Successful", detail: "Saved", life: 3000 });
                                        });

                                        setDownloadUrls(dUrl);

                                        console.log(`download url list ${JSON.stringify(downloadUrls)}`);
                                    })
                                    .then(() => {
                                        Axios.post(`http://localhost:8080/api/maintenance`, {
                                            username: product.data.username,
                                            message: product.data.message,
                                            attatchment: downloadUrls[0],
                                            count: product.data.count,

                                            ticketnumber: product.data.ticketnumber,
                                            clientphone: "-",

                                            propertyName: product.data.propertyName,
                                            rop: "-",
                                        });
                                    });
                            }
                        );
                        return fn;
                    })
                );

                console.log(`download urls    ${JSON.stringify(downloadUrls)}`);

                // await addDoc(collection(db, "cheapestHour"), {
                //   city: product.data.city,

                //   hourlyRate: inputFields,
                // }).then((res) => {
                //   toast.current.show({
                //     severity: "success",
                //     summary: "Successful",
                //     detail: "City Data Created",
                //     life: 3000,
                //   });
                setProductDialog(false);
                setProduct(emptyProduct);
                // });
            } catch (err) {
                alert(err);
            }
        }
    };

    const editProduct = (product) => {
        console.log(`product details  ${JSON.stringify(product)}`);
        setProduct({ ...product });
        setInputFields(product.data.file_uri);
        setProductDialog(true);
    };

    const confirmDeleteProduct = (product) => {
        setProduct(product);
        setDeleteProductDialog(true);
    };

    const deleteProduct = () => {
        let _products = products.filter((val) => val.id !== product.id);
        setProducts(_products);
        setDeleteProductDialog(false);
        setProduct(emptyProduct);
        toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Product Deleted",
            life: 3000,
        });
    };

    // const findIndexById = (id) => {
    //     let index = -1;
    //     for (let i = 0; i < products.length; i++) {
    //         if (products[i].id === id) {
    //             index = i;
    //             break;
    //         }
    //     }

    //     return index;
    // };

    const exportCSV = () => {
        dt.current.exportCSV();
    };

    // const confirmDeleteSelected = () => {
    //     setDeleteProductsDialog(true);
    // };

    const deleteSelectedProducts = () => {
        let _products = products.filter((val) => !selectedProducts.includes(val));
        setProducts(_products);
        setDeleteProductsDialog(false);
        setSelectedProducts(null);
        toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Products Deleted",
            life: 3000,
        });
    };

    // const onCategoryChange = (e) => {
    //     let _product = { ...product };
    //     _product["category"] = e.value;
    //     setProduct(_product);
    // };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _product = { ...product };
        _product.data[`${name}`] = val;

        setProduct(_product);
    };

    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _product = { ...product };
    //     _product[`${name}`] = val;

    //     setProduct(_product);
    // };
    // const timeNow = () => {
    //     var d = new Date(),
    //         h = (d.getHours() < 10 ? "0" : "") + d.getHours(),
    //         m = (d.getMinutes() < 10 ? "0" : "") + d.getMinutes();
    //     return h + ":" + m;
    // };

    const formatModule = (testdata) => {
        var formatted = [];
        for (var i = 0; i < testdata.length; i++) {
            formatted.push(testdata[i].data);
        }
        console.log(`Data formatted ${JSON.stringify(formatted)}`);
        return formatted;
    };
    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-send" className="p-button-success mr-2" onClick={openNew} />

                    <Button label="Calendar" icon="pi pi-eye" className="p-button-success mr-2" style={{ backgroundColor: "purple" }} onClick={openCalendar} />

                    <Button label="Close Ticket" icon="pi pi-eye" className="p-button-success mr-2" disabled={!selectedProducts} style={{ backgroundColor: "purple" }} onClick={openNew} />
                    {/* {JSON.stringify(testdata[0].data)} */}
                    {/* {JSON.stringify(notificationFormat[0].data.title)} */}
                    <Button
                        label="Log Out"
                        icon="pi pi-switch"
                        className="p-button-danger"
                        onClick={logout}
                        //  disabled={!selectedProducts || !selectedProducts.length}
                    />
                </div>
            </React.Fragment>
        );
    };

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        );
    };

    // const codeBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Codsdse</span>
    //             {rowData.data["firstname"]}
    //         </>
    //     );
    // };

    // const nameBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Name</span>
    //             {rowData.data["lastname"]}
    //         </>
    //     );
    // };

    // const imageBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Image</span>

    //             <img src={`${rowData.data["image_url"]}`} alt={"user image"} className="shadow-2" width="100" />
    //         </>
    //     );
    // };

    // const priceBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Price</span>
    //             {rowData.data["email"]}
    //         </>
    //     );
    // };

    // const categoryBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Component</span>
    //             {rowData.data["component_id"]}
    //         </>
    //     );
    // };

    const propertyNameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Property Name</span>
                {rowData.data["propertyName"]}
            </>
        );
    };

    const ticketNumberBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ticket Number</span>
                {rowData.data["ticketnumber"]}
            </>
        );
    };
    const maintenanceMessageBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Maintenance Message</span>
                {rowData.data["message"]}
            </>
        );
    };
    const ticketStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ticket Status</span>
                {rowData.data["ticketstatus"]}
            </>
        );
    };

    // const statusBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">Status</span>
    //             {JSON.stringify(rowData)}
    //             {/* <span className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}>{rowData.inventoryStatus}</span> */}
    //         </>
    //     );
    // };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editProduct(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteProduct(rowData)} />
            </div>
        );
    };

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Manage Maintenance Request</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveProduct} />
        </>
    );
    const deleteProductDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteProduct} />
        </>
    );
    const deleteProductsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );
    // const selectedItemTemplate = (option) => {
    //     if (option) {
    //         return (
    //             <div className="inline-flex align-items-center py-1 px-2 bg-primary text-primary border-round mr-2">
    //                 {/* <span className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px', height: '12px' }} /> */}
    //                 <span>{option}</span>
    //             </div>
    //         );
    //     }

    //     return "Select Cheapest Hours";
    // };
    // const itemTemplate = (option) => {
    //     return (
    //         <div className="flex align-items-center">
    //             {/* <span className={`mr-2 flag flag-ng`} style={{ width: '18px', height: '12px' }} /> */}
    //             <span>{option}</span>
    //         </div>
    //     );
    // };
    const handleFormChange = (index, event) => {
        let data = [...inputFields];

        if (event.target.name === "file") {
            data[index][event.target.name] = event.target.files[0];
            setInputFields(data);
        } else {
            data[index][event.target.name] = event.target.value;
            setInputFields(data);
        }
    };
    const addFields = () => {
        let newfield = { document_type: "", file: "" };

        setInputFields([...inputFields, newfield]);
    };
    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    };

    const eventClick = (e) => {
      const { title, start, end } = e.event;
      setEventDialog(true);
      setClickedEvent(e.event);
      setChangedEvent({ title, start, end, allDay: null });
  }

  // useEffect(() => {
  //     const eventService = new EventService();
  //     eventService.getEvents().then(data => setEvents(data));
  // }, [])

  const save = () => {
      setEventDialog(false);

      clickedEvent.setProp('title', changedEvent.title)
      clickedEvent.setStart(changedEvent.start)
      clickedEvent.setEnd(changedEvent.end)
      clickedEvent.setAllDay(changedEvent.allDay)

      setChangedEvent({ title: '', start: null, end: '', allDay: null });
  };

  const reset = () => {
      const { title, start, end } = clickedEvent;
      setChangedEvent({ title, start, end, allDay: null });
  };

  const footer = (
      <>
          <Button type="button" label="Save" icon="pi pi-check" className="p-button-text" onClick={save} />
          <Button type="button" label="Reset" icon="pi pi-refresh" className="p-button-text" onClick={reset} />
      </>
  );
    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt}
                        value={users}
                        selection={selectedProducts}
                        onSelectionChange={(e) => setSelectedProducts(e.value)}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25]}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        globalFilter={globalFilter}
                        emptyMessage="No document(s) found."
                        header={header}
                        responsiveLayout="scroll"
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>

                        <Column field="category" header="Ticket Number" sortable body={ticketNumberBodyTemplate} headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>
                        <Column field="rating" header="Maintenance Message" body={maintenanceMessageBodyTemplate} sortable headerStyle={{ width: "30%", minWidth: "10rem" }}></Column>

                        <Column field="rating" header="Ticket Status" body={ticketStatusBodyTemplate} sortable headerStyle={{ width: "14%", minWidth: "10rem" }}></Column>

                        <Column field="rating" header="Property Name" body={propertyNameBodyTemplate} sortable headerStyle={{ width: "50%", minWidth: "10rem" }}></Column>

                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={productDialog} style={{ width: "900px" }} header="Maintenance Request" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                        <div className="field">
                            <label htmlFor="name">Maintenance Status*</label>

                            <Dropdown
                                id="projectname"
                                value={product.data.propertyName}
                                onChange={(e) => onInputChange(e, "propertyName")}
                                options={modules.map((pn) => {
                                    return pn.data["property_name"];
                                })}
                                placeholder="Select Property"
                            ></Dropdown>
                            {submitted && !product.data.propertyName && <small className="p-invalid">Property Name is required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="name">Ticket Number*</label>
                            <InputText id="username" type="text" value={product.data.ticketnumber} onChange={(e) => onInputChange(e, "ticketnumber")} required autoFocus className={classNames({ "p-invalid": submitted && !product.data.ticketnumber })} />
                        </div>

                        <div className="field">
                            <label htmlFor="name">Maintenance Status*</label>

                            <Dropdown id="projectname" value={product.data.ticketstatus} onChange={(e) => onInputChange(e, "ticketstatus")} options={ticketStatus} placeholder="Select Maintenance Status"></Dropdown>
                            {submitted && !product.data.ticketstatus && <small className="p-invalid">Maintenance Statusis required.</small>}
                        </div>

                        <div className="field">
                            <label htmlFor="name">Maintenance Date*</label>
                            <Calendar name="hearingdate" showIcon showButtonBar value={product.data.maintenancedate} onChange={(e) => onInputChange(e, "maintenancedate")}></Calendar>
                        </div>

                        {inputFields.map((input, index) => {
                            return (
                                <div key={index} className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="price">Document Type</label>
                                        <Dropdown name="document_type" value={input.document_type} onChange={(event) => handleFormChange(index, event)} options={doc_types} placeholder="Select Document Type" />
                                        {submitted && !input.document_type && <small className="p-invalid">Document Type is required.</small>}
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="courtroomnumber"> Choose File to Upload </label>
                                        <input
                                            name="file"
                                            type="file"
                                            onChange={(event) => handleFormChange(index, event)}
                                            accept="image/*"
                                            // onChange={handleChange}
                                        />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="courtroomnumber"> Upload </label>
                                        <Button label="click to Upload" icon="pi pi-upload" className="p-button-success mr-2" onClick={addFields} />
                                    </div>
                                    <div className="field col">
                                        <label htmlFor="courtroomnumber"> Delete </label>
                                        <Button
                                            label="Delete"
                                            icon="pi pi-trash"
                                            className="p-button-danger"
                                            onClick={() => removeFields(index)}
                                            //  disabled={!selectedProducts || !selectedProducts.length}
                                        />
                                        {/* <Button label="Add" icon="pi pi-check" className="p-button-success mr-2" onClick={addFields} /> */}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="field col">
                            <label htmlFor="courtroomnumber"> Action </label>
                            <Button label="Add" icon="pi pi-check" className="p-button-success mr-2" onClick={addFields} />
                        </div>

                        <div className="field">
                            <label htmlFor="name">Message*</label>

                            <InputTextarea rows={15} id="username" type="text" value={product.data.message} onChange={(e) => onInputChange(e, "message")} required autoFocus className={classNames({ "p-invalid": submitted && !product.data.message })} />
                        </div>
                   
                    </Dialog>


                    <Dialog visible={calendarDialog} style={{ width: "900px" }} header="Maintenance Calendar Monitor" modal className="p-fluid" maximizable  onHide={hideDialog}>
                    <div className="grid">
            <div className="col-12">
                

                    <FullCalendar
                    
                    // themeSystem=''
                    events={events} eventClick={eventClick} initialDate="2023-01-01" initialView='dayGridMonth' 
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                        headerToolbar={{ left: 'prev,next today', center: 'title',
                         right: 'dayGridMonth,timeGridWeek,timeGridDay' }} 
                        editable selectable selectMirror
                        endParam='ehhg'
                        eventMinHeight={'90'}
                        
                        startParam='djj'

                        eventBackgroundColor='green'
                        
                         dayMaxEvents />

                    <Dialog visible={eventDialog && !!clickedEvent} style={{ width: '450px' }} header="Event Details" footer={footer} modal closable onHide={() => setEventDialog(false)}>
                        <div className="p-fluid">
                            <div className="field">
                                <label htmlFor="title">Title</label>
                                <InputText id="title" value={changedEvent.title} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ title: e.target.value } })} required autoFocus />
                            </div>
                            <div className="field">
                                <label htmlFor="start">From</label>
                                <PRCalendar id="start" value={changedEvent.start} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ start: e.value } })} showTime appendTo={document.body} />
                            </div>
                            <div className="field">
                                <label htmlFor="end">To</label>
                                <PRCalendar id="end" value={changedEvent.end} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ end: e.value } })} showTime appendTo={document.body} />
                            </div>
                            <div className="field-checkbox">
                                <Checkbox inputId="allday" name="allday" value="All Day" checked={!!changedEvent.allDay} onChange={(e) => setChangedEvent({ ...changedEvent, ...{ allDay: e.value } })} />
                                <label htmlFor="allday">All Day</label>
                            </div>
                        </div>
                    </Dialog>
                </div>
            </div>
        
                       </Dialog>



                    <Dialog visible={deleteProductDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && (
                                <span>
                                    Are you sure you want to delete <b>{product.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteProductsDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteProductsDialogFooter} onHide={hideDeleteProductsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                            {product && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AljaberMaintenance, comparisonFn);
