import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
// import { Dropdown } from "primereact/dropdown";
// import { Chart } from "primereact/chart";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
import { Menu } from "primereact/menu";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import ProductService from "../service/ProductService";
import axios from "axios";
import { Toast } from "primereact/toast";







const Dashboard = () => {
  
    // let emptyTenant = {
    //     id: null,
    //     propertyId: "",
    //     flatNo: "",
    //     propertyId: "",
    //     tenantName: "",
    //     price: "",
    //     spaceSqm: "",
    //     idno: "",
    //     floor: "",
    //     tenantType: "",
    //     bedroom: "",
    //     bathroom: "",
    //     electrical: "",
    //     water: "",
    //     contact: "",
    //     dob: "",
    //     emailaddress: "",
    //     startdate: "",
    //     enddate: "",
    //     mopayment: "",
    //     maintaindate: "",
    //     paymentStatus: "",
    //     contract: "",
    //     image: null,
    //     description: "",
    //     propertyId: null,
    //     price: 0,
    //     quantity: 0,
    //     rating: 0,
    //     inventoryStatus: "INSTOCK",
    // };

    const [displayBasic, setDisplayBasic] = useState(false);


    // const [products, setProducts] = useState(null);
    // const [productsThisWeek, setProductsThisWeek] = useState(null);
    // const [productsLastWeek, setProductsLastWeek] = useState(null);
    const [tenants, setTenants] = useState(null);
    const [selectedtenants, setSelectedTenants] = useState(null);
    const [selectedtenantscontractfilepath, setSelectedTenantsContractFilePath] = useState(null);
    const [fileattach, setfileattach] = useState(null);
    const [tenantId, setTenantId] = useState(null);
    const toast = useRef(null);

    const items = [
        {
            label: "Shipments",
            items: [
                { label: "Tracker", icon: "pi pi-compass" },
                { label: "Map", icon: "pi pi-map-marker" },
                { label: "Manage", icon: "pi pi-pencil" },
            ],
        },
    ];

    const menuRef = useRef(null);
    // const chartRef = useRef(null);
    const handleFile = (e) => {
        // const files = e.target.files[0];
        setfileattach(e.target.files[0]);
        // alert(`fileeeeeeeeeeeeeeeeeeeee ${JSON.stringify(e.target.files)}`)
    };
    useEffect(() => {
        const productService = new ProductService();
        // productService.getProducts().then((data) => setProducts(data));
        // productService.getProducts().then((data) => setProductsThisWeek(data));
        // productService.getProductsMixed().then((data) => setProductsLastWeek(data));
        productService.getTenants().then((data) => setTenants(data));
    }, []);

    

    

    const menuToggle = (event) => {
        menuRef.current.toggle(event);
    };

    // const refreshDataset = (event) => {
    //     chartRef.current.reinit(event);
    // };

    const formatCurrency = (value) => {
        return value.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
        });
    };

   


    
    const onClickHandler = () => {
        var formData = new FormData();
        alert(`file name ${JSON.stringify(tenantId)}`);
        formData.append("file", fileattach);
        formData.append("tenantId", tenantId);
        formData.append("contract", fileattach.name);
        const data = {
            tenantId: tenantId,
            contract: fileattach.name,
        };
        axios
            .post("http://localhost:8080/contractUpload", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((res) => {
                axios
                    .post("http://localhost:8080/api/contractupdate", data)
                    .then((res) => {
                        toast.current.show({ severity: "success", summary: "Successful", detail: "Contract Uploaded", life: 3000 });
                        setDisplayBasic(false);
                    })
                    .catch((Exception) => {
                        toast.current.show({ severity: "failed", summary: "Failed", detail: "Contract Upload Failed", life: 3000 });
                    });
            });
    };
    const basicDialogFooter = <Button type="button" label="Upload" onClick={onClickHandler} icon="pi pi-check" className="p-button-secondary" />;

    return (
        <div className="layout-dashboard">
            <div className="grid">
                <Toast ref={toast} />
                <Dialog header="Contract" visible={displayBasic} style={{ width: "30vw" }} modal footer={basicDialogFooter} onHide={() => setDisplayBasic(false)}>
                    <p>
                        Upload <b>{selectedtenants}</b> Contract(s)
                    </p>

                    <iframe title="selectedtenants" key={selectedtenants} src={"http://localhost:8080/contracts/" + selectedtenantscontractfilepath} width="100%" height="500px"></iframe>

                    <div className="field col-12 md:col-6">
                        <label htmlFor="city">Upload Contract</label>
                        <InputText id="city" type="file" accept="application/pdf" onChange={handleFile} />
                    </div>
                </Dialog>
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-1">
                        <span className="overview-icon">
                            <i className="pi pi-shopping-cart"></i>
                        </span>
                        <span className="overview-title">Rents</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">4</div>
                                <div className="overview-subtext">Completed Rents</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number" style={{ height: "20px" }}></div>
                                <div className="overview-subtext">
                                    <b>Rents of May 2021</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-2">
                        <span className="overview-icon">
                            <i className="pi pi-dollar"></i>
                        </span>
                        <span className="overview-title">Paid</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">$100,000</div>
                                <div className="overview-subtext">Amount Paid</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">$9,640</div>
                                <div className="overview-subtext">40 Rents Paid</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-3">
                        <span className="overview-icon">
                            <i className="pi pi-users"></i>
                        </span>
                        <span className="overview-title">Not Paid</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">$100,000</div>
                                <div className="overview-subtext">Not Paid</div>
                            </div>
                            <div className="col-6">
                                {/* <div className="overview-number">25402</div> */}
                                <div className="overview-subtext">
                                    <b>34 Rents not paid</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 md:col-6 xl:col-3">
                    <div className="card grid-nogutter widget-overview-box widget-overview-box-4">
                        <span className="overview-icon">
                            <i className="pi pi-comment"></i>
                        </span>
                        <span className="overview-title">Maintenance Request</span>
                        <div className="grid overview-detail">
                            <div className="col-6">
                                <div className="overview-number">12</div>
                                <div className="overview-subtext">New</div>
                            </div>
                            <div className="col-6">
                                <div className="overview-number">85</div>
                                <div className="overview-subtext">Responded</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 xl:col-12">
                    <div className="card grid-nogutter orders">
                        <div className="card-header">
                            <h4>Payments Activity</h4>
                            <Menu id="orders-button" popup model={items} ref={menuRef} appendTo={document.body}></Menu>
                            <Button type="button" icon="pi pi-search" className="p-button-text p-button-secondary" onClick={menuToggle} aria-controls="orders-button" aria-haspopup="true"></Button>
                        </div>

                       
                    </div>
                </div>

                {tenants
                    ? tenants.map((ten) => {
                          return (
                              <div className="col-12 lg:col-4">
                                  <div className="card widget-tasks">
                                      <h4>{ten.tenantName}</h4>
                                      <p>{ten.tenantType}</p>
                                      <p>{ten.emailaddress}</p>
                                      <p>{ten.dob}</p>
                                      <p>
                                          <b>Left to Pay: $2,000</b>
                                      </p>

                                      <div className="task task-1">
                                          <div className="task-name">
                                              <span>May 2021</span> &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                                              {ten.price ? (ten.price !== "-" ? formatCurrency(ten.price) : ten.price) : null}
                                          </div>
                                          <div className="task-progress">
                                              <div></div>
                                          </div>
                                      </div>
                                      <div>
                                          {/* http://localhost:8080/ */}
                                          <Button label="Send Email" className="p-button-danger mr-2 mb-2" />
                                          <Button label="Send Receipt" className="p-button-success mr-2 mb-2" />
                                          <Button label="Send Notice" className="mr-2 mb-2" />
                                          <Button
                                              label="Edit"
                                              className="p-button-outlined p-button-info mr-2 mb-2"
                                              onClick={() => {
                                                  setTenantId(ten.tenantId);
                                                  setSelectedTenants(ten.tenantName);
                                                  setDisplayBasic(true);
                                                  setSelectedTenantsContractFilePath(ten.contract);
                                              }}
                                              disabled={!ten.tenantId}
                                          ></Button>
                                      </div>
                                  </div>
                              </div>
                          );
                      })
                    : null}

                <div className="col-12 lg:col-4"></div>

              
            </div>
        </div>
    );
};

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(Dashboard, comparisonFn);
